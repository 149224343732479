import React from "react"
import { graphql } from "gatsby"
import logoSvg from "../../images/dv-logo.svg"
import NotFound from "../../components/routes/not_found"

const NotFoundPage = props => {
  if (props.data && props.data.storyblokEntry.content) {
    const content = JSON.parse(props.data.storyblokEntry.content)

    return (
      <main>
        <NotFound content={content} />
      </main>
    )
  } else {
    return (
      <main style={{ padding: "64px 32px" }}>
        <div style={{ maxWidth: "800px", margin: "auto" }}>
          <img
            src={logoSvg}
            alt="Donkervoort logo"
            style={{ width: "120px" }}
          />
          <img src={logoSvg} alt="Donkervoort logo" sx={{ width: "120px" }} />
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <a href="/en/">The way back to the homepage</a>
        </div>
      </main>
    )
  }
}

export default NotFoundPage

export const Head = props => <title>404: Not found</title>

export const pageQuery = graphql`
  query {
    storyblokEntry(
      field_component: { eq: "not_found_404" }
      lang: { eq: "en" }
    ) {
      id
      name
      lang
      content
    }
  }
`
